<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        target-components-header

        div(class="now-target-content")
          div(class="now-target-content-item")
            div ターゲット名
            div {{ data.name }}
            div 現在の達成率
            div {{ percent }}%
            div 累計ｷﾞﾌﾄﾎﾟｲﾝﾄ
            div {{ data.current_point.toLocaleString() }}
            div 目標ｷﾞﾌﾄﾎﾟｲﾝﾄ
            div {{ data.target_point.toLocaleString() }}
            div チャレンジ内容
            div {{ data.content }}
          div(class="now-target-content-item")
            img(v-show="data.banner_store_path" :src="$store.state.system.config.storage_addr_list.public + data.banner_store_path")

        div(class="rank-account")
          div(v-for="(item, index) in rankList" class="rank-account-item")
            div(v-if="index < 3" class="rank-account-ranking")
              span(style="color: #fff;") {{ index + 1 }}
              img(:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
            div(v-else class="rank-account-ranking") 
              span {{ index + 1 }}
            div(@click="onAvatarClick(item.profile.id)")
              <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.viewer_info.current_title" />
            div(class="rank-account-name") {{ item.profile.account_name }}
            div {{ 'P' + item.total_points.toLocaleString() }}

        div(class="thank-you-button")
          img(@click="onGift()" class="img-button" src="/img/orei_p.png")

      div(class="content-right")
        .pr-list
          pr-banner(v-if="!ui.profile")
          component-profile-index(v-if="ui.profile" :profile_id="profile_id" v-on:profileclose="profileclose()")

    component-footer-index
</template>

<style src="./Detail.scss" lang="scss" scoped>
</style>

<script>
import TargetComponentsHeader from "../components/Header";
import { getTarget, getTargetRankList } from '@/api/target'
import PanThumb from '@/components/PanThumb'

export default {
  name: "SettingTargetDetail",
  components: {
    TargetComponentsHeader,
    PanThumb
  },
  data() {
    return {
      ui: {
        profile: false,
      },
      target_id: -1,
      profile_id: -1,
      percent: -1,
      data: {
        target_id: undefined,
        name: undefined,
        target_point: undefined,
        content: undefined,
        banner_store_path: undefined,

        current_point: undefined,
      },
      rankList: undefined,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.target_id = this.$route.params.target_id;
    const ret1 = await getTarget(this.target_id);
    if (ret1.data) {
      this.data.target_id = ret1.data.id;
      this.data.name = ret1.data.name;
      this.data.target_point = ret1.data.target_point;
      this.data.content = ret1.data.content;
      this.data.banner_store_path = ret1.data.banner_store_path;

      this.data.current_point = ret1.data.current_point;

      const per = this.data.current_point * 100 / this.data.target_point;
      this.percent = per > 100 ? 100 : Math.floor(per);

      const ret2 = await getTargetRankList(this.data.target_id);
      this.rankList = ret2.data;
    }

  },
  updated() {},
  destroyed() {},
  methods: {
    async test() {},
    onAvatarClick(profile_id) {
      this.ui.profile = false;

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    },
    onGift() {
      this.$router.push({ name: 'SettingTargetGift', params: {target_id: this.target_id} })
    },
    profileclose() {
      this.ui.profile = false;
    },
    avatarImageUrl(url){
      return url
    }

  }
};
</script>
